import { apiFactory } from "@/api/factories/apiFactory";
class healthCheckService {
    _healthCheckApi = apiFactory.useHealthCheckApi();
    async checkConnection() {
        let result = false;
        const request = await this._healthCheckApi.getHealthCheck();
        if (request.success) {
            result = true;
        }
        return result;
    }
}
export default healthCheckService;
