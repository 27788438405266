import { httpClient } from "@/helpers/httpClient";
export default class synchronousApiResponse {
    responseData;
    errorResponse;
    get success() {
        return this.errorResponse === undefined;
    }
    isProblem(response) {
        const castResponse = response;
        return castResponse.status !== undefined && castResponse.title !== undefined;
    }
    constructor(requestMethod, request) {
        let initialResponse;
        switch (requestMethod) {
            case "GET":
                initialResponse = httpClient.getSynchronous(request);
                break;
            default:
                this.errorResponse = { title: "Unsupported method", isProblemDetails: true };
                return;
        }
        if (this.isProblem(initialResponse)) {
            this.errorResponse = initialResponse;
        }
        else {
            this.responseData = initialResponse;
        }
    }
}
