import { defineStore } from "pinia";
export const useApplicationStore = defineStore({
    id: "application",
    state: () => ({
        menuItems: null,
        profileDetails: null
    }),
    getters: {
        applicationMenu: (state) => state.menuItems,
        profile: (state) => state.profileDetails
    }
});
