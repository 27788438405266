import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-055115f3"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "app-page overflow-auto" };
const _hoisted_2 = { class: "p-3 px-lg-5" };
import { ref } from "vue";
import { RouterView } from "vue-router";
import AppBar from "@/components/root/AppBarComponent.vue";
import BottomBar from "@/components/root/BottomBarComponent.vue";
import HealthCheck from "@/components/root/HealthCheckComponent.vue";
import SidebarNav from "@/components/root/SidebarNavComponent.vue";
import AuthService from "@/services/application/authService";
export default _defineComponent({
    __name: 'App',
    setup(__props) {
        const authSvc = new AuthService();
        const fullPage = ref(true);
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("main", null, [
                (!fullPage.value)
                    ? (_openBlock(), _createBlock(SidebarNav, {
                        key: 0,
                        onLogout: _unref(authSvc).logout
                    }, null, 8, ["onLogout"]))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_1, [
                    (!fullPage.value)
                        ? (_openBlock(), _createBlock(AppBar, { key: 0 }))
                        : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_2, [
                        _createVNode(_unref(RouterView))
                    ]),
                    (!fullPage.value)
                        ? (_openBlock(), _createBlock(BottomBar, {
                            key: 1,
                            onLogout: _unref(authSvc).logout
                        }, null, 8, ["onLogout"]))
                        : _createCommentVNode("", true)
                ]),
                _createVNode(HealthCheck)
            ]));
        };
    }
});
