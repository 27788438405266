import { httpClient } from "@/helpers/httpClient";
class apiResponse {
    response;
    errorResponse;
    get success() {
        return this.errorResponse === undefined;
    }
    ready;
    isProblem(error) {
        return error.status !== undefined;
    }
    constructor(requestMethod, request) {
        if (request == undefined || requestMethod == undefined || request.url == undefined) {
            console.error("malformed request received");
            this.errorResponse = {
                isProblemDetails: true,
                title: "malformed request received"
            };
            this.ready = Promise.reject();
        }
        let requestFunc;
        switch (requestMethod) {
            case "GET":
                if (request.query) {
                    requestFunc = async () => httpClient.get(request);
                }
                requestFunc = async () => httpClient.get(request);
                break;
            case "DELETE":
                requestFunc = async () => httpClient.delete(request);
                break;
            case "PATCH":
                requestFunc = async () => httpClient.patch(request);
                break;
            case "POST":
                requestFunc = async () => httpClient.post(request);
                break;
            case "PUT":
                requestFunc = async () => httpClient.put(request);
                break;
            default:
                break;
        }
        this.ready = new Promise((resolve) => {
            requestFunc()
                .then(response => {
                this.response = response;
                resolve(undefined);
            })
                .catch(error => {
                if (this.isProblem(error)) {
                    this.errorResponse = error;
                }
                else {
                    this.errorResponse = {
                        detail: "An unexpected error occurred",
                        status: 500,
                        isProblemDetails: true
                    };
                }
                resolve(undefined);
            });
        });
    }
}
export default apiResponse;
