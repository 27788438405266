import { httpClient } from "@/helpers/httpClient";
import user from "@/models/application/User";
export default class {
    resource = "/api/application/user";
    async getUser() {
        const response = await httpClient.get({ url: `${this.resource}` });
        if (response.isAuthenticated) {
            return new user(response.name, response.isAuthenticated, response.permissions, response.nexusUserId, response.employeeId);
        }
        else {
            return new user(undefined, false, undefined);
        }
    }
}
