import userApi from "@/api/application/userApi";
import applicationApi from "@/api/application/profileApi";
import healthCheckApi from "@/api/application/healthCheckApi";
import { myLearningCourseApi, myLearningEmployeeApi, myLearningTrackingApi } from "@/api/myLearning";
const apis = {
    user: new userApi(),
    profile: new applicationApi(),
    healthCheck: new healthCheckApi(),
    myLearningCourse: new myLearningCourseApi(),
    myLearningEmployee: new myLearningEmployeeApi(),
    myLearningTracking: new myLearningTrackingApi()
};
export const apiFactory = {
    useUserApi: () => apis.user,
    useHealthCheckApi: () => apis.healthCheck,
    useProfileApi: () => apis.profile,
    useMyLearningCourseApi: () => apis.myLearningCourse,
    useMyLearningEmployeeApi: () => apis.myLearningEmployee,
    useMyLearningTrackingApi: () => apis.myLearningTracking
};
