import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "nav px-3 py-1 bg-light"
};
const _hoisted_2 = ["href", "aria-controls"];
const _hoisted_3 = {
    width: "25",
    height: "25",
    fill: "currentColor"
};
const _hoisted_4 = ["xlink:href"];
const _hoisted_5 = _createElementVNode("div", { class: "d-flex ms-auto" }, [
    _createElementVNode("div", null, "   ")
], -1);
import { sidebarId } from "./constants";
import { useAuthStore } from "@/stores/authStore";
import bootstrapIcons from "@/assets/images/bootstrapIcons";
export default _defineComponent({
    __name: 'AppBarComponent',
    setup(__props) {
        const authStore = useAuthStore();
        return (_ctx, _cache) => {
            return (_unref(authStore).user.isAuthenticated)
                ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
                    _createElementVNode("a", {
                        class: "d-md-none",
                        alt: "Menu",
                        "aria-current": "page",
                        href: `#${_unref(sidebarId)}`,
                        "data-bs-toggle": "offcanvas",
                        "aria-controls": _unref(sidebarId)
                    }, [
                        (_openBlock(), _createElementBlock("svg", _hoisted_3, [
                            _createElementVNode("use", {
                                "xlink:href": `${_unref(bootstrapIcons)}#list`
                            }, null, 8, _hoisted_4)
                        ]))
                    ], 8, _hoisted_2),
                    _hoisted_5
                ]))
                : _createCommentVNode("", true);
        };
    }
});
