import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6b55778a"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["title", "type"];
const _hoisted_2 = ["xlink:href"];
const _hoisted_3 = {
    key: 1,
    class: "btn-icon ms-1 link-icon",
    fill: "currentColor"
};
const _hoisted_4 = ["xlink:href"];
import { computed, useSlots } from "vue";
import bootstrapIcons from "@/assets/images/bootstrapIcons";
export default _defineComponent({
    __name: 'BaseButtonComponent',
    props: {
        small: { type: Boolean },
        tooltip: { default: "" },
        bootstrapIcon: { default: "" },
        outlineButton: { type: Boolean, default: true },
        buttonStyle: { default: "primary" },
        buttonType: { default: "button" }
    },
    setup(__props) {
        const props = __props;
        const slots = useSlots();
        let hasContent = false;
        if (slots.default) {
            hasContent = slots.default().some(element => element.children?.length);
        }
        const buttonClass = computed(() => {
            const classes = new Array();
            classes.push(`btn-${props.outlineButton && props.buttonStyle != "link" ? "outline-" : ""}${props.buttonStyle}`);
            if (props.small) {
                classes.push("small");
            }
            return classes.join(" ");
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("button", {
                class: _normalizeClass(["btn", _unref(buttonClass)]),
                title: __props.tooltip,
                type: __props.buttonType
            }, [
                (__props.bootstrapIcon && __props.buttonStyle != 'link')
                    ? (_openBlock(), _createElementBlock("svg", {
                        key: 0,
                        class: _normalizeClass([_unref(hasContent) == true ? 'me-1' : '', "btn-icon"]),
                        fill: "currentColor"
                    }, [
                        _createElementVNode("use", {
                            "xlink:href": `${_unref(bootstrapIcons)}#${props.bootstrapIcon}`
                        }, null, 8, _hoisted_2)
                    ], 2))
                    : _createCommentVNode("", true),
                _renderSlot(_ctx.$slots, "default"),
                (__props.buttonStyle == 'link')
                    ? (_openBlock(), _createElementBlock("svg", _hoisted_3, [
                        _createElementVNode("use", {
                            "xlink:href": `${_unref(bootstrapIcons)}#box-arrow-up-right`
                        }, null, 8, _hoisted_4)
                    ]))
                    : _createCommentVNode("", true)
            ], 10, _hoisted_1));
        };
    }
});
