<template>
  <div class="container py-4">
    <div class="row card-sm mx-1 mx-sm-0">
      <div class="col-12 p-0">
        <div class="my-nexus-header p-3">
          <img src="@/assets/images/NexusLogoLarge.png" height="60" alt="Nexus Logo" />
        </div>
      </div>
      <div class="col-12 mt-3">
        <div class="card-body">
          <h1>
            Welcome to Nexus Training.
          </h1>
          <p>
            To access your training courses please use My Nexus.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.my-nexus-header {
  background-color: shade-color($green, 5%) !important;
}
.card-sm {
  @include media-breakpoint-up(sm) {
    // apply card and shadow settings from sm up (@extend is not possible across media queries)
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-border-width: 1px;
    --bs-card-border-color: var(--bs-border-color-translucent);
    --bs-card-border-radius: 0.2rem;
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: 0;
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: #fff;
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
  }
}
</style>