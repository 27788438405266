import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2a516f28"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["id"];
const _hoisted_2 = { class: "offcanvas-header text-light" };
const _hoisted_3 = {
    class: "me-2",
    width: "40",
    height: "40",
    fill: "currentColor"
};
const _hoisted_4 = ["xlink:href"];
const _hoisted_5 = _withScopeId(() => _createElementVNode("span", { class: "fs-4" }, "Nexus Training", -1));
const _hoisted_6 = ["data-bs-target"];
const _hoisted_7 = {
    class: "me-2",
    width: "40",
    height: "40",
    fill: "currentColor"
};
const _hoisted_8 = ["xlink:href"];
const _hoisted_9 = _withScopeId(() => _createElementVNode("span", { class: "fs-4" }, "Nexus Training", -1));
const _hoisted_10 = _withScopeId(() => _createElementVNode("hr", { class: "text-secondary" }, null, -1));
const _hoisted_11 = { class: "nav nav-pills flex-column mb-auto" };
const _hoisted_12 = ["aria-controls", "href"];
const _hoisted_13 = {
    class: "me-2",
    width: "24",
    height: "24",
    fill: "currentColor"
};
const _hoisted_14 = ["xlink:href"];
const _hoisted_15 = ["id"];
const _hoisted_16 = { class: "nav nav-pills flex-column ms-5" };
const _hoisted_17 = { class: "d-none d-md-block" };
const _hoisted_18 = _withScopeId(() => _createElementVNode("hr", { class: "m-0 text-secondary" }, null, -1));
import { sidebarId } from "./constants";
import { ref } from "vue";
import AppsButton from "./AppsButtonComponent.vue";
import ProfileButton from "./ProfileButtonComponent.vue";
import bootstrapIcons from "@/assets/images/bootstrapIcons";
import { useApplicationStore } from "@/stores/applicationStore";
export default _defineComponent({
    __name: 'SidebarNavComponent',
    emits: ["logout"],
    setup(__props, { emit }) {
        const offCanvas = ref();
        const closeMobileOffCanvas = ref();
        const applicationStore = useApplicationStore();
        function closeOffcanvas(item) {
            if (item.children == undefined) {
                closeMobileOffCanvas.value?.click();
            }
        }
        return (_ctx, _cache) => {
            const _component_RouterLink = _resolveComponent("RouterLink");
            return (_unref(applicationStore).menuItems != undefined)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    id: _unref(sidebarId),
                    ref_key: "offCanvas",
                    ref: offCanvas,
                    class: "offcanvas-md offcanvas-start d-flex flex-column flex-shrink-0 p-3 overflow-auto shadow-sm"
                }, [
                    _createElementVNode("div", _hoisted_2, [
                        (_openBlock(), _createElementBlock("svg", _hoisted_3, [
                            _createElementVNode("use", {
                                "xlink:href": `${_unref(bootstrapIcons)}#award`
                            }, null, 8, _hoisted_4)
                        ])),
                        _hoisted_5,
                        _createElementVNode("button", {
                            ref_key: "closeMobileOffCanvas",
                            ref: closeMobileOffCanvas,
                            type: "button",
                            class: "btn-close text-light",
                            "data-bs-dismiss": "offcanvas",
                            "data-bs-target": `#${_unref(sidebarId)}`,
                            "aria-label": "Close"
                        }, "   ", 8, _hoisted_6)
                    ]),
                    _createVNode(_component_RouterLink, {
                        to: "/",
                        class: "d-none ps-3 d-md-flex align-items-center mb-3 mb-md-0 me-md-auto link-light text-decoration-none"
                    }, {
                        default: _withCtx(() => [
                            (_openBlock(), _createElementBlock("svg", _hoisted_7, [
                                _createElementVNode("use", {
                                    "xlink:href": `${_unref(bootstrapIcons)}#award`
                                }, null, 8, _hoisted_8)
                            ])),
                            _hoisted_9
                        ]),
                        _: 1
                    }),
                    _hoisted_10,
                    _createElementVNode("ul", _hoisted_11, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(applicationStore).menuItems, (menu) => {
                            return (_openBlock(), _createElementBlock("li", {
                                key: menu.routeName,
                                class: "nav-item align-items-center py-1"
                            }, [
                                _createElementVNode("div", {
                                    "data-bs-toggle": "collapse",
                                    "aria-controls": `#${menu.routeName}-submenu`,
                                    href: `#${menu.menuName}-submenu`
                                }, [
                                    _createVNode(_component_RouterLink, {
                                        id: menu.routeName,
                                        to: { name: menu.routeName, params: menu.params },
                                        class: "d-flex nav-link link-light align-items-center",
                                        onClick: ($event) => (closeOffcanvas(menu))
                                    }, {
                                        default: _withCtx(() => [
                                            (_openBlock(), _createElementBlock("svg", _hoisted_13, [
                                                _createElementVNode("use", {
                                                    "xlink:href": `${_unref(bootstrapIcons)}#${menu.icon}`
                                                }, null, 8, _hoisted_14)
                                            ])),
                                            _createElementVNode("span", null, _toDisplayString(menu.label), 1)
                                        ]),
                                        _: 2
                                    }, 1032, ["id", "to", "onClick"])
                                ], 8, _hoisted_12),
                                _createElementVNode("div", {
                                    id: `${menu.menuName}-submenu`,
                                    class: "collapse"
                                }, [
                                    _createElementVNode("ul", _hoisted_16, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menu.children, (childMenuItem) => {
                                            return (_openBlock(), _createElementBlock("li", {
                                                key: childMenuItem.routeName,
                                                class: "nav-item align-items-center submenu mb-1"
                                            }, [
                                                _createVNode(_component_RouterLink, {
                                                    id: childMenuItem.routeName,
                                                    to: { name: childMenuItem.routeName, params: childMenuItem.params },
                                                    class: "d-flex nav-link link-light align-items-center",
                                                    onClick: ($event) => (closeOffcanvas(childMenuItem))
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createElementVNode("span", null, _toDisplayString(childMenuItem.label), 1)
                                                    ]),
                                                    _: 2
                                                }, 1032, ["id", "to", "onClick"])
                                            ]));
                                        }), 128))
                                    ])
                                ], 8, _hoisted_15)
                            ]));
                        }), 128))
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                        _hoisted_18,
                        _createVNode(AppsButton),
                        _createVNode(ProfileButton, {
                            "profile-details": _unref(applicationStore).profile,
                            onLogout: _cache[0] || (_cache[0] = ($event) => (emit('logout')))
                        }, null, 8, ["profile-details"])
                    ])
                ], 8, _hoisted_1))
                : _createCommentVNode("", true);
        };
    }
});
