import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import { Policy } from "@/enums/Policy";
import { differenceInMinutes } from "date-fns";
import AccessDeniedView from "@/views/error/AccessDeniedView.vue";
import HomeView from "@/views/root/HomeView.vue";
import SignedOutView from "@/views/root/SignedOutView.vue";
import LandingPageView from "@/views/root/LandingPageView.vue";
import TakeCourseView from "@/views/courses/TakeCourseView.vue";
import AuthService from "@/services/application/authService";
const AUTH_CHECK_THRESHOLD = 10;
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: "/",
            name: "landingPage",
            component: LandingPageView,
            meta: {
                requiresAuth: false,
                requiredPolicy: [Policy.SYS_ADMIN]
            }
        },
        {
            path: "/home",
            name: "home",
            component: HomeView,
            meta: {
                title: "Home",
                requiresAuth: true,
                requiredPolicy: [Policy.SYS_ADMIN]
            }
        },
        {
            path: "/apps",
            name: "apps",
            component: HomeView,
            meta: {
                title: "Applications",
                requiredPolicy: [Policy.SYS_ADMIN]
            }
        },
        {
            path: "/accessDenied",
            name: "accessDenied",
            component: AccessDeniedView,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/signedout",
            name: "SignedOut",
            component: SignedOutView,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/completeModule/:courseId/:employeeId",
            name: "completeModule",
            component: TakeCourseView,
            props: true,
            meta: {
                fullPage: true,
                requiresAuth: true
            }
        }
    ]
});
router.beforeEach(async (targetRoute) => {
    document.title = `Nexus Training ${targetRoute.meta.title ? ` | ${targetRoute.meta.title}` : ""}`;
    const authStore = useAuthStore();
    const authSvc = new AuthService();
    const requiresAuth = targetRoute.matched.some((route) => route.meta.requiresAuth);
    function needsAuthCheck() {
        const authCheck = differenceInMinutes(new Date(), authStore.lastAuthenticated) >
            AUTH_CHECK_THRESHOLD;
        return authCheck;
    }
    function authenticated() {
        return authStore.user?.isAuthenticated ?? false;
    }
    if (requiresAuth ?? true) {
        if (!authenticated() || needsAuthCheck()) {
            await authSvc.getUser();
            if (!authenticated()) {
                authSvc.login(targetRoute.fullPath);
                return false;
            }
        }
        if (!authStore.user.meetsPolicies((targetRoute.meta.requiredPolicy ?? []))) {
            router.push("AccessDenied");
        }
    }
});
export default router;
