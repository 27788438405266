import synchronousApiResponse from "@/models/root/synchronousApiResponse";
class myLearningEmployeeApi {
    resource = "/api/myLearning";
    getMyLearningEmployee(employeeId) {
        const request = new synchronousApiResponse("GET", {
            url: `${this.resource}/employee/${employeeId}/details`
        });
        if (request.success && request.responseData) {
            return request.responseData;
        }
        console.error(request.errorResponse);
        return { isValid: false };
    }
}
export default myLearningEmployeeApi;
