import { apiFactory } from "@/api/factories/apiFactory";
import { useApplicationStore } from "@/stores/applicationStore";
class applicationService {
    _applicationApi = apiFactory.useProfileApi();
    _applicationStore = useApplicationStore();
    async initialiseApplication() {
        const allMenuItems = [
            {
                menuName: "dashboard",
                label: "Dashboard",
                routeName: "home",
                icon: "bar-chart-fill"
            }
        ];
        const profile = (await this._applicationApi.getProfile()).response;
        this._applicationStore.menuItems = allMenuItems;
        this._applicationStore.profileDetails = profile ?? null;
    }
    async getProfile() {
        if (this._applicationStore.profile == null) {
            await this.initialiseApplication();
        }
        return this._applicationStore.profile;
    }
}
export default applicationService;
