import { apiResponse } from "@/models/root";
class myLearningCourseApi {
    resource = "/api/myLearning";
    async getCourse(myLearningCourseId) {
        const response = new apiResponse("GET", {
            url: `${this.resource}/courses/${myLearningCourseId}`
        });
        await response.ready;
        return response;
    }
    async getActiveCourse() {
        const response = new apiResponse("GET", {
            url: `${this.resource}/activeCourse/`
        });
        await response.ready;
        return response;
    }
    async setActiveCourse(myLearningCourseId) {
        const activeCourse = { courseId: myLearningCourseId };
        const response = new apiResponse("POST", {
            url: `${this.resource}/activeCourse/`,
            body: activeCourse
        });
        await response.ready;
        return response;
    }
    async deactivateActiveCourse(completeModule) {
        const response = new apiResponse("DELETE", {
            url: `${this.resource}/activeCourse${(completeModule ? "?completeModule=true" : "/")}`,
        });
        await response.ready;
        return response;
    }
}
export default myLearningCourseApi;
