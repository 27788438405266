import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-45889c52"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "dropdown"
};
const _hoisted_2 = { class: "nav nav-pills flex-column" };
const _hoisted_3 = { class: "nav-item align-items-center" };
const _hoisted_4 = {
    id: "dropdown_user",
    href: "#",
    class: "d-flex nav-link link-light align-items-center text-decoration-none dropdown-toggle",
    "data-bs-toggle": "dropdown",
    "aria-expanded": "false"
};
const _hoisted_5 = ["src", "width", "height"];
const _hoisted_6 = { key: 0 };
const _hoisted_7 = {
    class: "dropdown-menu text-small shadow",
    "aria-labelledby": "dropdownUser"
};
const _hoisted_8 = ["href"];
import { computed } from "vue";
export default _defineComponent({
    __name: 'ProfileButtonComponent',
    props: {
        profileDetails: null,
        avatarOnly: { type: Boolean }
    },
    emits: ["logout"],
    setup(__props, { emit }) {
        const props = __props;
        const iconDimensions = computed(() => props.avatarOnly ?? false ? 25 : 32);
        return (_ctx, _cache) => {
            return (__props.profileDetails)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("ul", _hoisted_2, [
                        _createElementVNode("li", _hoisted_3, [
                            _createElementVNode("a", _hoisted_4, [
                                _createElementVNode("img", {
                                    src: __props.profileDetails.avatarUrl.toString(),
                                    alt: "",
                                    width: _unref(iconDimensions),
                                    height: _unref(iconDimensions),
                                    class: "rounded-circle me-2"
                                }, null, 8, _hoisted_5),
                                (!__props.avatarOnly ?? true)
                                    ? (_openBlock(), _createElementBlock("strong", _hoisted_6, _toDisplayString(__props.profileDetails.userName), 1))
                                    : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("ul", _hoisted_7, [
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        href: __props.profileDetails.profileUrl.toString(),
                                        class: "dropdown-item align-items-center",
                                        target: "_blank"
                                    }, " My Profile ", 8, _hoisted_8)
                                ]),
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        href: "#",
                                        class: "dropdown-item align-items-center",
                                        onClick: _cache[0] || (_cache[0] = ($event) => (emit('logout')))
                                    }, " Sign Out ")
                                ])
                            ])
                        ])
                    ])
                ]))
                : _createCommentVNode("", true);
        };
    }
});
