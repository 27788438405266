import { apiResponse } from "@/models/root";
import synchronousApiResponse from "@/models/root/synchronousApiResponse";
class myLearningTrackingApi {
    resource = "/api/myLearning";
    async getCourseTracking(myLearningCourseId, courseContentId) {
        const tracking = new apiResponse("GET", {
            url: `${this.resource}/courseTracking/${myLearningCourseId}/${courseContentId}`
        });
        await tracking.ready;
        return tracking;
    }
    async addMyLearningActivity(myLearningCourseId, courseContentId, activity) {
        const request = new apiResponse("POST", {
            url: `${this.resource}/courseTracking/${myLearningCourseId}/${courseContentId}/activities`,
            body: activity
        });
        await request.ready;
        return request;
    }
    getMyLearningActivitiesSynchronous(employeeId, myLearningCourseId, courseContentId) {
        const response = new synchronousApiResponse("GET", {
            url: `${this.resource}/courseTracking/${myLearningCourseId}/${courseContentId}/activities`,
            query: { "employeeId": employeeId.toString() }
        });
        return response;
    }
    async addMyLearningEvent(myLearningCourseId, courseContentId, mLEvent) {
        const request = new apiResponse("POST", {
            url: `${this.resource}/courseTracking/${myLearningCourseId}/${courseContentId}/events`,
            body: mLEvent
        });
        await request.ready;
        return request;
    }
    async updateCourseProgress(myLearningCourseId, courseContentId, progress) {
        const response = new apiResponse("PUT", {
            url: `${this.resource}/courseTracking/${myLearningCourseId}/${courseContentId}/progress`,
            resourceId: myLearningCourseId.toString(),
            body: progress
        });
        await response.ready;
        return response;
    }
    getProgressSynchronous(myLearningCourseId, employeeId) {
        const response = new synchronousApiResponse("GET", {
            url: `${this.resource}/courseTracking/${myLearningCourseId}/1/progress`,
            query: employeeId ? { "employeeId": employeeId.toString() } : undefined
        });
        if (response.responseData) {
            return response.responseData;
        }
        return {
            moduleComplete: false,
            sections: []
        };
    }
}
export default myLearningTrackingApi;
