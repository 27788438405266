import { apiFactory } from "@/api/factories/apiFactory";
import { useAuthStore } from "@/stores/authStore";
import { differenceInMinutes } from "date-fns";
class authService {
    _userApi = apiFactory.useUserApi();
    _authStore = useAuthStore();
    AUTH_CHECK_THRESHOLD = 10;
    login(returnUrl) {
        window.location.href = `/application/auth/login?origin=${returnUrl}`;
    }
    logout() {
        window.location.href = "/application/auth/logout";
        this._authStore.$reset();
    }
    async getUser() {
        if (!this._authStore.userDetails || this.needsAuthCheck()) {
            const user = await this._userApi.getUser();
            if (user.isAuthenticated) {
                this._authStore.userDetails = user;
                this._authStore.lastAuthenticatedDate = new Date();
            }
            else {
                this._authStore.$reset();
            }
        }
        return this._authStore.userDetails;
    }
    needsAuthCheck() {
        const authCheck = differenceInMinutes(new Date(), this._authStore.lastAuthenticated) > this.AUTH_CHECK_THRESHOLD;
        return authCheck;
    }
}
export default authService;
