import { apiFactory } from "@/api/factories/apiFactory";
class myLearningService {
    _myLearningCourseApi = apiFactory.useMyLearningCourseApi();
    _myLearningEmployeeApi = apiFactory.useMyLearningEmployeeApi();
    _myLearningTrackingApi = apiFactory.useMyLearningTrackingApi();
    async getCourseDetails(myLearningCourseId) {
        const request = await this._myLearningCourseApi.getCourse(myLearningCourseId);
        if (request.success && request.response) {
            return request.response;
        }
        return null;
    }
    async openMyLearningCourse(myLearningCourseId) {
        const request = await this._myLearningCourseApi.getCourse(myLearningCourseId);
        if (request.success && request.response) {
            await this._myLearningCourseApi.setActiveCourse(myLearningCourseId);
            return request.response;
        }
        return null;
    }
    async closeMyLearningCourse(completeModule) {
        await this._myLearningCourseApi.deactivateActiveCourse(completeModule);
    }
    async getActiveCourse() {
        const activeCourse = (await this._myLearningCourseApi.getActiveCourse());
        if (activeCourse.success && activeCourse.response) {
            return activeCourse.response;
        }
        return null;
    }
    myLearningJsonConverter(myLearningObject) {
        if (myLearningObject == null) {
            return {};
        }
        Object.keys(myLearningObject).forEach(key => {
            const pascalCaseKey = key.charAt(0).toUpperCase() + key.substring(1);
            if (pascalCaseKey != key) {
                myLearningObject[pascalCaseKey] = myLearningObject[key];
            }
        });
        return myLearningObject;
    }
    async getCourseTracking(myLearningCourseId, courseContentId) {
        const tracking = await this._myLearningTrackingApi.getCourseTracking(myLearningCourseId, courseContentId);
        if (tracking.success && tracking.response) {
            return tracking.response;
        }
        return null;
    }
    async addMyLearningActivity(myLearningCourseId, courseContentId, activity) {
        const response = await this._myLearningTrackingApi.addMyLearningActivity(myLearningCourseId, courseContentId, activity);
        if (!response.success) {
            Promise.reject(`Attempt to add activity to course ${myLearningCourseId}/${courseContentId} failed - ${response.errorResponse}`);
        }
        return Promise.resolve();
    }
    getMyLearningActivities(employeeId, myLearningCourseId, courseContentId) {
        const response = this._myLearningTrackingApi.getMyLearningActivitiesSynchronous(employeeId, myLearningCourseId, courseContentId);
        if (response.success && response.responseData) {
            return response.responseData;
        }
        else {
            console.error(response.errorResponse);
        }
        return new Array();
    }
    async addMyLearningEvent(myLearningCourseId, courseContentId, mLEvent) {
        const response = await this._myLearningTrackingApi.addMyLearningEvent(myLearningCourseId, courseContentId, mLEvent);
        if (!response.success) {
            Promise.reject(`Attempt to add event to course ${myLearningCourseId}/${courseContentId} failed - ${response.errorResponse}`);
        }
        return Promise.resolve();
    }
    async updateCourseProgress(myLearningCourseId, courseContentId, progress) {
        const response = await this._myLearningTrackingApi.updateCourseProgress(myLearningCourseId, courseContentId, progress);
        if (!response.success) {
            Promise.reject(`Attempt to update progress for course ${myLearningCourseId}/${courseContentId} failed - ${response.errorResponse}`);
        }
        return Promise.resolve();
    }
    getEmployeeDetail(employeeId) {
        if (employeeId == 0) {
            return {
                isValid: false
            };
        }
        return this._myLearningEmployeeApi.getMyLearningEmployee(employeeId);
    }
    getProgressSynchronous(myLearningCourseId, employeeId) {
        const response = this._myLearningTrackingApi.getProgressSynchronous(myLearningCourseId, employeeId);
        return response;
    }
}
export default myLearningService;
